//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Browse',
  props: {
    rowsCount: {
      type: Number,
      default: 5,
    },
  },
}
