export const CRYPTO_CURRENCIES = [
  'BTC',
  'ETH',
  'XRP',
  'BCH',
  'EOS',
  'XLM',
  'LTC',
  'TRX',
  'ETC',
  'DCR',
]

export const FIAT_CURRENCY_SYMBOLS = {
  EUR: '€',
  GBP: '£',
  USD: '$',
  CNY: '元',
  RUB: '₽',
  TRY: '₺',
}
