export const currencyFormat = (value, options = { currency: 'USD' }) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    ...options,
  }).format(value)

export const compactFormat = (value, options) =>
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...options,
  }).format(value)
