//
//
//
//
//
//
//
//
//
//
//

import prettyValue from '~/helpers/prettyValue'

export default {
  name: 'Percent',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    valueFormatted () {
      return prettyValue(this.value, 'ratio', { showSign: true })
    },
  },
}
