//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import findKey from 'lodash/findKey'
import Percent from '~/components/buy-crypto/Percent'
import CurrencyIcon from '~/components/svg-icons/CurrencyIcon'
import { currencyFormat } from '~/helpers/numberFormat'

export default {
  name: 'Browse',
  components: { Percent, CurrencyIcon },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState({
      quoteCurrency: state => state.coins.quoteCurrency,
      coinsNameMapping: state => state.coins.coinsNameMapping,
    }),
  },
  methods: {
    rateFormat (value) {
      const rate = value.cents / 100
      return currencyFormat(rate, { currency: this.quoteCurrency })
    },
    to (ticker) {
      const name =
          findKey(this.coinsNameMapping, c => c === ticker) || ticker
      return `/buy-cryptocurrency/${name}`
    },
  },
}
