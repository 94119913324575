import assign from 'lodash/assign'
import round from 'lodash/round'
import fromPairs from 'lodash/fromPairs'
import toPairs from 'lodash/toPairs'
import { CRYPTO_CURRENCIES, FIAT_CURRENCY_SYMBOLS } from './params'

export const transformConfig = {
  // Cryptocurrencies rules
  ...fromPairs(
    CRYPTO_CURRENCIES.map(currency => [
      currency,
      {
        precision: currency === 'XRP' ? 6 : 8,
        postfix: ` ${currency}`, // NOTE `space` before currency
      },
    ]),
  ),
  // Fiat currencies rules
  ...fromPairs(
    toPairs(FIAT_CURRENCY_SYMBOLS).map(([currency, symbol]) => [
      currency,
      {
        precision: 2,
        prefix: symbol,
      },
    ]),
  ),
  // Other rules
  '%': {
    precision: 2,
    postfix: '%',
    beforeRound (absValue) {
      return 100 * absValue
    },
  },
  ratio: {
    precision: 2,
  },
}

function addThounsandSeparator (value, separator = ',') {
  return value.replace(/(\d)(?=(\d{3})+\.)/g, `$1${separator}`)
}

export default function prettyValue (
  value = 0,
  type = null,
  {
    showSign = false,
    hideCurrencySymbol = false,
    useThousandSeparator = true,
  } = {},
) {
  if (!type || !transformConfig[type]) {
    throw new Error(`Unknown value type (${type})`)
  }
  const {
    precision = 15,
    prefix = '',
    postfix = '',
    beforeRound = x => x,
  } = transformConfig[type]

  const sign = value > 0 ? '+' : value < 0 ? '-' : ''
  const optionalSign = sign === '-' || showSign ? sign : ''
  const absValue = Math.abs(value)

  let rounded = round(beforeRound(absValue), precision).toFixed(precision)
  if (useThousandSeparator) {
    rounded = addThounsandSeparator(rounded)
  }

  const infinitableRounded = isFinite(absValue) ? rounded : '∞'

  const optionalPrefix = !hideCurrencySymbol ? prefix : ''
  const optionalPostfix = !hideCurrencySymbol ? postfix : ''

  return optionalSign + optionalPrefix + infinitableRounded + optionalPostfix
}

export function prettyDelta (before, after, type, options) {
  options = assign({ showSign: true }, options)

  return prettyValue(after - before, type, options)
}

function relativeDelta (before, after) {
  return (after - before) / before
}

export function prettyRelativeDelta (before, after, options) {
  options = assign({ showSign: true }, options)

  const myRelativeDelta = relativeDelta(before, after)
  return prettyValue(myRelativeDelta, '%', options)
}
