//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import 'vue-resize/dist/vue-resize.css'
import findKey from 'lodash/findKey'
import { mapState } from 'vuex'
import { ResizeObserver } from 'vue-resize'
import CurrencyIcon from '~/components/svg-icons/CurrencyIcon'
import Percent from '~/components/buy-crypto/Percent'
import Browse from '~/components/buy-crypto/Browse'
import TableShimmer from '~/components/buy-crypto/Shimmer/Table'
import { currencyFormat } from '~/helpers/numberFormat'

const TABLET_WIDTH = 995

export default {
  name: 'Table',
  components: {
    CurrencyIcon,
    Percent,
    Browse,
    ResizeObserver,
    TableShimmer,
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    compact: false,
  }),
  computed: {
    ...mapState({
      quoteCurrency: state => state.coins.quoteCurrency,
      coinsNameMapping: state => state.coins.coinsNameMapping,
    }),
    fee () {
      return 0.04
    },
  },
  mounted () {
    this.compact = window.innerWidth <= TABLET_WIDTH
  },
  methods: {
    onRowClick (item) {
      const name =
          findKey(this.coinsNameMapping, c => c === item.ticker) ||
          item.ticker
      this.$router.push(`/buy-cryptocurrency/${name}`)
    },

    currency (value) {
      const rate = value / 100
      return currencyFormat(rate, { currency: this.quoteCurrency })
    },
    updateSizes ({ width }) {
      this.compact = width <= TABLET_WIDTH
    },
  },
}
