//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Browse from './Browse'
export default {
  components: { Browse },
  props: {
    compact: {
      type: Boolean,
      default: false,
    },
  },
}
